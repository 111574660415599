import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { Radio, Stack, Text } from '@angellist/adapt';

export type OtherProps = {
  error?: string;
  isDisabled?: boolean;
  items: {
    label: string;
    value: string | number;
  }[];
};

export type RadioGroupProps = {
  isRequired?: boolean;
  defaultValue?: string;
  shouldUnregister?: boolean;
  direction?: 'vertical' | 'horizontal';
};

export type Props = RadioGroupProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const RadioGroup = ({
  control,
  name,
  isRequired: isRequiredProp,
  rules,
  defaultValue,
  isDisabled,
  error,
  shouldUnregister,
  items,
  direction = 'horizontal',
}: Props) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    render={(field) => (
      <Stack gap="50">
        <Stack direction={direction} gap="100">
          {items.map((item) => (
            <Radio
              key={item.value}
              isDisabled={isDisabled}
              isSelected={item.value === field.value}
              defaultSelected={item.value === defaultValue}
              onChange={() => field.onChange(item.value)}
            >
              {item.label}
            </Radio>
          ))}
        </Stack>
        {!!error && (
          <Text size="200" color="danger">
            {error}
          </Text>
        )}
      </Stack>
    )}
    rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
    shouldUnregister={shouldUnregister}
  />
);

RadioGroup.displayName = 'RadioGroup';
